.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    z-index: 9999;
  }
  
  .spinner {
    border: 8px solid $white;
    border-top: 8px solid $clientprimary;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
