.cover-section{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 80vh;
    position: relative;

    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $primary;
        opacity: 0.6;
        z-index: 1;
    }

    .cover-container{
        position: relative;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        z-index: 2;

        h1{
            display: block;
            font-size: 4em;
            font-weight: 700;
            text-transform: uppercase;
            color: $white;
        }
    
        span{
            margin-top: 20px;
            display: block;
            font-size: 1.5em;
            font-weight: 500;
            color: $white;
            line-height: 1.2em;
        }        

        a{
            margin-top: 40px;
            width: fit-content;
            display: flex;

            .button{
                margin-left: 15px;
                border-color: $white;
                color: white;

                &:hover{
                    background-color: $white;
                    color: $primary;
                }
            }
        }
    }

    .cover-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

// Mobile
@media only screen and (max-width: 600px) {

    body{

        .cover-section{
            height: 60vh;
            margin-top: 60px;
        }
        
        .cover-section .cover-container h1{
            font-size: 2em;
        }

        .cover-section .cover-container span{
            font-size: 1.2em;
            font-weight: 300;
        }
    }
}

// Laptop
@media only screen and (min-width: 1100px) and (max-width: 1440px) {

    body{

        .cover-section{
            height: 90vh;
        }
        
        .cover-section .cover-container h1{
            font-size: 3em;
        }
    }
}