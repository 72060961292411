.numeros-empresa-section{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: $clientprimary;
    background-image: linear-gradient(135deg, $clientprimary 0%, $clientprimaryhover 100%);
    margin-top: 100px;
    padding: 100px 0;

    .numeros-empresa-titulo{
        font-size: 2.2em;
        color: $white;
        text-align: center;
        text-transform: uppercase;
    }

    .numeros-empresa-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;

        .numeros-empresa-item{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .numero{
                font-size: 4em;
                color: $white;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }

            .info-numero{
                font-size: 1.4em;
                color: $white;
                font-weight: 300;
                width: 100%;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}


// Mobile
@media only screen and (max-width: 600px) {

    body{

        .numeros-empresa-section .numeros-empresa-container .numeros-empresa-item{
            margin: 40px 0;
        }
    }
}