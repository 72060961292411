.produtos-section{
    box-sizing: border-box;
    padding: 0 50px;
    margin-top: 50px;
}

.filtro-produtos-container{
    position: relative;
    background-color: $forth;
    padding: 40px 0;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    height: fit-content;

    form{

        h3{
            font-size: 1.4em;
        }

        .filtro-principal, .filtro-avancado{
            margin-top: 20px;

            label{
                width: 100%;
                margin: 20px 0;
                display: flex;
                flex-wrap: wrap;

                select, input{
                    width: 100%;
                    padding: 10px;
                    margin-top: 10px;
                    background-color: $white;
                    border-color: $transparent;
                    opacity: 1;
                    border-radius: 5px;
                }
            }
        }

        button{
            width: 100%;
            border-radius: 10px;
            background-color: $white;
            font-size: 1em;
            border: 1px solid $primary;
            padding: 10px 0;
            margin: 10px 0;
            cursor: pointer;
            transition-duration: $animationtime;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover{
                background-color: $primary;
                color: $white;
            }
        }

        .filtro-avancado-button{
            margin-top: 20px;
        }
    }
}


// Mobile
@media only screen and (max-width: 600px) {

    body{

        .produtos-section{
            padding: 0;
        }
    }
}