.footer-section{
    padding: 50px 0;
    border-top: 1px solid $primary;
    box-sizing: border-box;

    .footer-content{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;

        .footer-left{
            display: flex;
            align-items: center;

            span{
                font-size: 1.2em;
                color: $secundary;
            }
        }

        .footer-center{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            
            ul{
                li{
                    width: 100%;
                    margin: 10px 0;

                    a{
                        font-size: 1.2em;
                        color: $primary;
                    }

                    &:hover{

                        a{
                            color: $third;
                        }
                    }
                }
            };
        }
        .footer-right{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            
            ul{
                li{
                    width: 100%;
                    margin: 10px 0;

                    a{
                        font-size: 1.2em;
                        color: $primary;
                    }

                    &:hover{

                        a{
                            color: $third;
                        }
                    }
                }
            };
        }
    }
}


// Responsive
@media only screen and (max-width: 600px) {
 
    body{

        .footer-section .footer-content{
            align-content: flex-start;
            align-items: flex-start;
        }

        .footer-section .footer-content .footer-left{
            justify-content: center;
        }

        .footer-section .footer-content .footer-center{
            margin-top: 20px;
            justify-content: center;
            align-items: flex-start;
            align-content: flex-start;
            margin-top: 40px;

            li{
                text-align: center;
            }
        }

        .footer-section .footer-content .footer-right{
            margin-top: 20px;
            justify-content: center;
            align-items: flex-start;
            align-content: flex-start;
            margin-top: 30px;
        }

        .footer-section .footer-content .footer-right ul li{
            text-align: center;
        }

    }

}