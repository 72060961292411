.gridrowfull{
    width: $gridwidth;
}
.gridrow{
    width: $gridwidth;
    margin: 0 auto;
    @media(min-width: $tablet){
        width: $gridwidtht;
    }
    @media(min-width: $desktop){
        width: $gridwidthd;
    }
}

.gridrowfull, .gridrow{
    display: flex;
    flex-wrap: wrap;
    .col{
        flex: 1;
        box-sizing: border-box;
        padding-left: calc($gridgutter / 2);
        padding-right: calc($gridgutter / 2);
    }
    @for $i from 1 through $gridcolumns {
        .col-#{$i} {
            box-sizing: border-box;
            width: percentage($i/$gridcolumns);
            padding-left: calc($gridgutter / 2);
            padding-right: calc($gridgutter / 2);
        }
        .offset-#{$i} {
            margin-left: percentage($i/$gridcolumns);
        }
    }
    @media(min-width: $tablet){
        .col-t{
            flex: 1;
            box-sizing: border-box;
            padding-left: calc($gridgutter / 2);
            padding-right: calc($gridgutter / 2);
        }
        .offset-t{
            margin-left: 0;
        }
        @for $i from 1 through $gridcolumns {
            .col-t-#{$i} {
                box-sizing: border-box;
                width: percentage($i/$gridcolumns);
                padding-left: calc($gridgutter / 2);
                padding-right: calc($gridgutter / 2);
            }
            .offset-t-#{$i} {
                margin-left: percentage($i/$gridcolumns);
            }
        }
    }
    @media(min-width: $desktop){
        .col-d{
            flex: 1;
            box-sizing: border-box;
            padding-left: calc($gridgutter / 2);
            padding-right: calc($gridgutter / 2);
        }
        .offset-d{
            margin-left: 0;
        }
        @for $i from 1 through $gridcolumns {
            .col-d-#{$i} {
                box-sizing: border-box;
                width: percentage($i/$gridcolumns);
                padding-left: calc($gridgutter / 2);
                padding-right: calc($gridgutter / 2);
            }
            .offset-d-#{$i} {
                margin-left: percentage($i/$gridcolumns);
            }
        }
    }
    &.nogutter{
        .col, .col-t, .col-d {
            padding: 0;
        }
        @for $i from 1 through $gridcolumns {
            .col-#{$i}, .col-t-#{$i}, .col-d-#{$i} {
                padding: 0;
            }
        }
    }
}