.info-anuncio-container{
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;

    .info-anuncio-nome{
        padding-bottom: 20px;

        h1{
            line-height: 1.2em;
            color: $clientprimary;
        }
    }

    .info-anuncio-breve{
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        border-top: 1px solid $primary;

        span{
            width: 100%;
            margin: 5px 0;
        }
    }

    .info-anuncio-preco{
        margin-top: 20px;

        span{
            font-size: 3em;
            font-weight: 500;
            color: $clientprimary;
        }
    }

    .ver-mais-caracteristicas{
        margin-top: 30px;

        a{
            color: $clientprimary;
            text-transform: uppercase;
            transition-duration: $animationtime;

            &:hover{
                color: $clientprimaryhover;
            }
        }
    }

    .contactar-button{
        margin-top: 30px;

        .contactar-email{
            margin-top: 10px;
            display: flex;
        }

        button{
            background-color: $clientprimary;
            color: $white;
            border-color: $clientprimary;

            &:hover{
                background-color: $clientprimaryhover;
            }
        }
    }
}