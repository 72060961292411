.galeria-produto-container {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    .galeria-produto-imagens {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        padding: 0;
        width: 100%;

        .imagem-principal {
            display: flex;
            transition: transform 0.5s ease-in-out;
            width: 100%;
            height: 450px;
            position: relative;

            .slide-image {
                flex: 0 0 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                width: 100%;
            }
        }

        .navegacao-imagens {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                background-color: $white;
                border: 1px solid $white;
                color: $primary;
                width: 50px;
                height: 50px;
                font-size: 1.5em;
                transition-duration: $animationtime;
                font-weight: 500;
                cursor: pointer;
                opacity: .3;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .miniaturas-imagens {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: auto; /* Enable horizontal scrolling */
        padding: 0;
        width: 100%; /* Ensure it takes full width */
        scroll-behavior: smooth; /* Smooth scrolling */

        /* Optional: Add scrollbar styling if desired */
        ::-webkit-scrollbar {
            height: 8px; /* Adjust scrollbar height */
        }

        ::-webkit-scrollbar-thumb {
            background-color: $third; /* Adjust scrollbar color */
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: $third; /* Darker color on hover */
        }

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            opacity: .5;
            margin: 10px 5px;
            border-radius: 5px;

            &:first-child {
                margin-left: 0;
            }

            &.ativa {
                opacity: 1;
            }
        }
    }
}

// Responsive
@media only screen and (max-width: 600px) {
    body{

        .galeria-produto-container .galeria-produto-imagens .imagem-principal{
            height: 350px;
        }

        .galeria-produto-container .galeria-produto-imagens .imagem-principal .slide-image{
            width: 100%;
        }
    }
}