.breadcrumbs{
    padding: 30px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 100px;

    a{
        text-transform: uppercase;
        margin: 0 5px;
        font-weight: 400;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        width: fit-content;
        opacity: .5;

        &:first-child{
            margin-left: 0;
        }

        &::after{
            content: '/';
            margin-left: 10px;
        }

        &:hover{
            opacity: 1;
        }
    }

    span{
        font-weight: 700;
    }
}

// Mobile
@media only screen and (max-width: 600px) {

    body{
        
        .breadcrumbs{
            padding: 20px 15px;
        }
    }
}