.descricao-produto-container {
    padding: 15px;
    box-sizing: border-box;

    .descricao-produto-conteudo {
        padding: 40px;
        background-color: $forth;
        border-radius: 10px;
        
        h2 {
            margin-bottom: 15px;
            font-size: 1.5rem;
            color: $clientprimary;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.6;
            color: $primary;
        }
    }
}

// Mobile
@media only screen and (max-width: 600px) {

    body{

        .descricao-produto-container .descricao-produto-conteudo{
            padding: 20px;
        }
        
        .caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item{
            width: 100%;
            margin: 10px 0;
        }
    }
}