.caracteristicas-produtos {
    margin-top: 30px;
    padding: 15px;
    box-sizing: border-box;

    .caracteristicas-produtos-container {
        background-color: $forth;
        padding: 40px;
        border-radius: 8px;
        box-sizing: border-box;

        h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
            color: $clientprimary;
        }

        .lista-caracteristicas {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .caracteristica-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            width: 48%;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }

            .label {
                font-weight: bold;
                margin-right: 5px;
                color: $primary;
            }

            .valor {
                color: $primary;
            }
        }
    }
}

// Mobile
@media only screen and (max-width: 600px) {

    body{

        .caracteristicas-produtos .caracteristicas-produtos-container{
            padding: 20px;
        }
        
        .caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item{
            width: 100%;
            margin: 10px 0;
        }
    }
}