.button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: 1.2em;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 20px;
    border: 1px solid $primary;
    background-color: $transparent;
    transition-duration: $animationtime;
    cursor: pointer;
    width: fit-content;

    &:hover{
        background-color: $primary;
        color: $white;
    }
}