.info-contactos-section{
    position: relative;
    flex-wrap: wrap;
    margin-top: 100px;

    .info-contactos-container{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .info-contactos-texto{
            margin-top: 100px;

            &:first-child{
                margin-top: 0;
            }

            h2, h3{
                color: $clientprimary;
                font-size: 2.2em;
                line-height: 1.2em;
            }
    
            p{
                margin-top: 20px;
                padding: 0;
                font-size: 1.4em;
                line-height: 1.2em;
            }
        }

        
    }
}


// Responsive
@media only screen and (max-width: 600px) {
    body{
        
        .info-contactos-section .info-contactos-localizacao .info-contactos-localizacao-mapa{
            margin-top: 50px;
        }
    }
}