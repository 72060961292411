$titlesfont: "Lato";
$titlesfontweights: "300;400;500;600;700;800;900";
$bodyfont: "Lato";
$bodyfontweights: "400";

$primary: #131518;
$secundary: #495057;
$third: #adb5bd;
$forth: #e9ecef;
$transparent: #00000000;
$white: #f8f9fa;

$clientprimary: #1F316F;
$clientprimaryhover: #111b3f;
$clientsecundary: #7b74a1;

$animationtime: 0.3s;
$letterspacing: 2px;

$shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; // Box shadow #37

$tablet: 750px;
$desktop: 1200px;

$gridcolumns: 12;
$gridgutter: 30px;

$gridwidth: 100%;
$gridwidtht: 90%;
$gridwidthd: $desktop - $gridgutter;

