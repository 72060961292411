.ligue-section{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: $clientprimary;
    background-image: linear-gradient(135deg, $clientprimary 0%, $clientprimaryhover 100%);
    margin-top: 100px;
    padding: 100px 0;

    .ligue-container{
        display: flex;
        justify-content: center;

        .ligue-content{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            h2{
                font-size: 2.2em;
                color: $white;
                text-align: center;
                text-transform: uppercase;
            }
    
            p{
                font-size: 1.4em;
                color: $white;
                line-height: 1.2em;
                margin-top: 20px;
                font-weight: 300;
            }

            a{
                position: relative;
                margin-top: 40px;

                button{
                    background-color: $transparent;
                    color: $white;
                    border-color: $white;

                    &:hover{
                        background-color: $white;
                        color: $clientprimary;
                    }
                }
            }
        }
    }
    
}