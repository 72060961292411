.lista-produtos-section{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 100px;

    .lista-produtos-container{
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        .aviso-texto{
            font-size: 2em;
            font-weight: 300;
            text-align: center;
            width: 100%;
            color: $primary;
        }

        .produtos-destaque-item{
            width: calc((100% / 4) - 20px);
            margin: 10px 10px;
            box-shadow: $shadow;
            padding-bottom: 20px;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            height: fit-content;

            &:hover{

                img{
                    transform: scale(1.1);
                }
            }

            .produtos-destaque-item-imagem{
                height: 250px;
                overflow: hidden;
                position: relative;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition-duration: $animationtime;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            .produtos-destaque-item-conteudo{
                

                .produtos-destaque-item-nome{
                    margin: 10px 0;
                    padding: 10px;

                    h3{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .produtos-destaque-item-caracteristicas{
                    margin: 10px 0;
                    display: flex;
                    flex-wrap: wrap;

                    span{
                        font-weight: 300;
                        margin: 5px 10px;
                        display: flex;
                        width: fit-content;
                    }

                    .preco{
                        display: block;
                        color: $clientprimary;
                        font-weight: 700;
                        font-size: 1.4em;
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}


// Mobile
@media only screen and (max-width: 600px) {

    body{

        .lista-produtos-section .lista-produtos-container {
            padding: 10px 15px;
            margin-top: 20px;
        }
    
        .lista-produtos-section .lista-produtos-container .produtos-destaque-item {
            width: 100%;
            overflow: visible;
        }
    
        .lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-imagem{
            height: 200px;
        }
    }
}