.toTop {
    position: fixed;
    bottom: 50px;
    right: -70px; // Start off-screen to the right
    width: 60px;
    height: 60px;
    background-color: $white;
    cursor: pointer;
    z-index: 1000;
    border-radius: 100%;
    padding: 10px;
    box-sizing: border-box;
    opacity: 0;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    box-shadow: $shadow;

    &.visible {
        right: 50px; // Move to the desired position
        opacity: 1;
    }

    &.hidden {
        right: -70px; // Move off-screen to the right
        opacity: 0;
    }
}

.toTop__arrow {
    width: 100%;
    height: 100%;
    transform: rotate(180deg); /* Rotate the arrow image to point upwards */
    filter: invert(100%);
}

// Responsive adjustments
@media only screen and (max-width: 600px) {
    body {
        
        .toTop{
            bottom: 21px;
        }

        .toTop.visible {
            right: 21px;
        }
    }
}