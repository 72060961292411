.intro-empresa-section{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .intro-empresa-texto{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 100px;

        h2, h3{
            color: $clientprimary;
            font-size: 2.2em;
        }

        p{
            margin-top: 20px;
            padding: 0;
            font-size: 1.4em;
            line-height: 1.2em;
        }

        a{
            margin-top: 40px;

            button{
                background-color: $clientprimary;
                color: $white;

                &:hover{
                    background-color: $clientprimaryhover;
                }
            }
        }
    }

    .intro-empresa-items{
        display: flex;
        justify-content: center;
        align-items: normal;
        flex-wrap: wrap;
        margin-top: 100px;
        padding: 0;

        .intro-empresa-texto{
            padding: 50px;
            box-sizing: border-box;
            box-shadow: $shadow;
            border-radius: 20px;
            margin: 15px;
            width: calc(50% - 30px);
            height: auto;
        }
    }
}

// Responsive
@media only screen and (max-width: 600px) {
    body{
        
        .intro-empresa-section .intro-empresa-items .intro-empresa-texto{
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
    }
}