.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.04) 0px 2px 4px -1px;
    padding: 20px 40px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    transition: top .6s ease-in-out;  // Add this line for smooth transition

    &.scrolled {
        top: -50vh;  // Adjust this value to control how far up the menu goes when scrolling
    }

    .logo {
        position: relative;
        width: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a{
            font-size: 1em;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: $letterspacing;
            color: $clientprimary;
            transition-duration: $animationtime;

            &:hover{
                color: $clientprimaryhover;
            }
        }

        img {
            max-height: 40px;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .menu-items {
        position: relative;
        width: 80%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.open {
            display: flex;
        }

        & > ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            li {
                position: relative;
                display: flex;
                justify-content: center;
                margin: 0 10px;
                font-size: 1em;
                text-transform: uppercase;
                letter-spacing: 2px;
                cursor: pointer;
                transition-duration: $animationtime;
                padding: 10px 20px;
                border-radius: 25px;
                display: flex;
                justify-content: center;

                a{
                    font-weight: 700;
                    transition-duration: $animationtime;
                    color: $clientprimary;
                }

                &:hover {

                    a{
                        font-weight: 700;
                        color: $clientprimaryhover;
                    }
                }

                

                & > ul {
                    position: absolute;
                    top: 70px;
                    background-color: $secundary;
                    padding: 20px;
                    border-radius: 25px;

                    li {
                        color: $white;
                        font-size: 0.8em;
                    }
                }
            }
        }
    }

    .hamburger {
        display: none;
        flex-direction: column;
        cursor: pointer;
    
        .bar {
            width: 25px;
            height: 3px;
            background-color: $primary;
            margin: 4px 0;
            transition: transform 0.4s ease, opacity 0.4s ease;
            transform-origin: 5px;
    
            &:nth-child(1) {
                &.open {
                    transform: rotate(45deg) translate(3px, 3px);
                }
            }
    
            &:nth-child(2) {
                &.open {
                    opacity: 0;
                }
            }
    
            &:nth-child(3) {
                &.open {
                    transform: rotate(-45deg) translate(5px, -5px);
                }
            }
        }
    }

    @media (max-width: 768px) {

        padding: 20px;

        .logo{

            img{
                max-height: 30px;
            }
        }

        .menu-items {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 40px);
            background-color: $white;
            padding: 20px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-70px); // Start off-screen
            transition: opacity $animationtime ease, transform $animationtime ease;
            display: flex;
            justify-content: center;

            &.open {
                opacity: 1;
                visibility: visible;
                transform: translateY(70px); // Move to the final position
            }

            & > ul {
                flex-direction: column;

                & > li {
                    width: 100%;
                    margin: 10px 0;

                    a{
                        font-weight: 700;
                        font-size: 1.2em;
                    }

                    &:hover{
                        background-color: $third;
                        color: $primary;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        width: 100%;
                    }

                    & > ul {
                        top: 0;
                        position: relative;
                        background-color: $third;
                        width: 100%;
                        padding: 20px 0;

                        & > li{
                            width: 100%;
                            padding: 10px 0;
                            margin: 10px 0;
                            color: $primary;
                            font-size: 1em;
                        }
                    }
                }
            }
        }

        .hamburger {
            display: flex;
        }


    }
}