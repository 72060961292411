// Normal Imports
@import './style/reset';
@import './style/variables';
@import './style/grid';
@import './style/fonts';

// Components
@import './components/menu/menu';
@import './components/toTop/toTop';
@import './components/pageTitle/pageTitle';
@import './components/footer/footer';
@import './components/cover/cover';
@import './components/button/button';
@import './components/produtoDestaque/produtoDestaque';
@import './components/listaProdutos/listaProdutos';
@import './components/galeriaProduto/galeriaProduto';
@import './components/infoAnuncio/infoAnuncio';
@import './components/breadcrumbs/breadcrumbs';
@import './components/caracteristicasProdutos/caracteristicasProdutos';
@import './components/descricaoProduto/descricaoProduto';
@import './components/sobreNos/sobreNos';
@import './components/contactoProduto/contactoProduto';
@import './components/filtroProdutos/filtroProdutos';
@import './components/introEmpresa/introEmpresa';
@import './components/numerosEmpresa/numerosEmpresa';
@import './components/infoContactos/infoContactos';
@import './components/ligue/ligue';
@import './components/loadingScreen/loadingScreen';

// Main SCSS
html, body{
    scroll-behavior: smooth;
//    overflow-x: hidden;
}

// Mobile
@media only screen and (max-width: 1200px) {
    
    *{

        &::-webkit-scrollbar {
            display: none !important;
        }

        -ms-overflow-style: none !important;  /* IE and Edge */
        scrollbar-width: none !important;  /* Firefox */
    }
    
}
