.contacto-produto-section {
    padding: 100px 0;
    background-color: $forth;
    display: flex;
    justify-content: center;

    &.produtos-contacto-section{
        margin-top: 50px;
    }

    .contacto-produto-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;
        background-color: $white;
        border-radius: 10px;
        padding: 100px 50px;
        box-shadow: $shadow;
        text-align: center;

        h2 {
            font-size: 2rem;
            color: $clientprimary;
            margin-bottom: 20px;
        }

        p {
            font-size: 1.4rem;
            line-height: 1.2em;
            color: $secundary;
            margin-bottom: 30px;
        }

        .contacto-produto-form {
            display: flex;
            flex-direction: column;
            align-items: center;

            .form-group {
                margin-bottom: 15px;
                width: 100%;
                text-align: left;
                margin-top: 20px;

                label {
                    display: block;
                    font-size: 1.4rem;
                    color: $primary;
                    margin-bottom: 5px;
                }

                input, textarea {
                    width: 100%;
                    padding: 10px;
                    font-size: 1.2rem;
                    border: 1px solid $forth;
                    border-radius: 5px;
                    box-sizing: border-box;
                    margin-top: 10px;
                }

                textarea {
                    resize: vertical;
                }
            }

            .contacto-produto-submit {
                margin-top: 20px;
                padding: 10px 20px;
                font-size: 1.2em;
                font-weight: 500;
                text-transform: uppercase;
                color: $white;
                background-color: $clientprimary;
                border: none;
                border-radius: 20px;
                cursor: pointer;
                transition: $animationtime;

                &:hover {
                    background-color: $clientprimaryhover;
                }
            }
        }
    }
}



// Mobile
@media only screen and (max-width: 600px) {

    body{
        
        .contacto-produto-section{
            padding: 50px 0;
        }

        .contacto-produto-section .contacto-produto-container{
            padding: 50px 20px;
        }
    }
}