.sobre-nos-container {
    padding: 15px;
    box-sizing: border-box;

    .sobre-nos-conteudo {
        padding: 100px 40px;
        border-radius: 10px;
        
        h2 {
            margin-bottom: 15px;
            font-size: 1.5rem;
            color: $clientprimary;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.6;
            color: $primary;
        }
    }
}

// Mobile
@media only screen and (max-width: 600px) {

    body{

        .sobre-nos-container .sobre-nos-conteudo{
            padding: 20px;
        }
    }
}