.produtos-destaque-section{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 50px 0;

    .produtos-destaque-titulo{

        h2{
            color: $clientprimary;
        }
    }

    .produtos-destaque-lista{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .produtos-destaque-item{
            width: calc((100% / 4) - 15px);
            margin: 0 10px;
            padding-bottom: 20px;
            box-shadow: $shadow;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            height: auto;

            &:hover{

                img{
                    transform: scale(1.1);
                }
            }

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }

            .produtos-destaque-item-imagem{
                height: 250px;
                overflow: hidden;
                position: relative;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition-duration: $animationtime;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            .produtos-destaque-item-conteudo{
                

                .produtos-destaque-item-nome{
                    margin: 10px 0;
                    padding: 10px;

                    h3{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: $primary;
                    }
                }

                .produtos-destaque-item-caracteristicas{
                    margin: 10px 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 5px;

                    span{
                        font-weight: 300;
                        margin: 5px 4px;
                        display: flex;
                        width: fit-content;
                    }

                    .preco{
                        display: block;
                        color: $clientprimary;
                        font-weight: 700;
                        font-size: 1.4em;
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}


// Mobile
@media only screen and (max-width: 600px) {

    body{
        .produtos-destaque-section .produtos-destaque-lista {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding: 10px 15px;
            margin-top: 20px;
        }
    
        .produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item {
            width: 200px;
            overflow: visible;
        }
    
        .produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-imagem{
            height: 200px;
        }
    }
}