.pagetitle-section{
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 50px;
    min-height: 50vh;

    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $clientprimaryhover;
        background-image: linear-gradient(135deg, $clientprimary 0%, $clientprimaryhover 100%);
        opacity: 1;
        z-index: 1;
        mix-blend-mode: soft-light;
    }

    &::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $primary;
        opacity: .3;
        z-index: 1;
    }

    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    h1{
        font-size: 3em;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        z-index: 2;
        color: $white;
    }

    p{
        margin-top: 10px;
        color: $white;
        z-index: 2;
        font-size: 1.4em;
        font-weight: 500;
    }
}

// Responsive
@media only screen and (max-width: 600px) {
    body{
        .pagetitle-section{
            padding-bottom: 50px;
            padding: 0 20px;
            box-sizing: border-box;
            margin-top: 60px;
        }       

        .pagetitle-section p{
            text-align: center;
            line-height: 1.2em;
            margin-top: 20px;
        }
    }
}