@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;800;900&family=Lato:wght@400&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.gridrowfull {
  width: 100%;
}

.gridrow {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 750px) {
  .gridrow {
    width: 90%;
  }
}
@media (min-width: 1200px) {
  .gridrow {
    width: 1170px;
  }
}

.gridrowfull, .gridrow {
  display: flex;
  flex-wrap: wrap;
}
.gridrowfull .col, .gridrow .col {
  flex: 1;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .col-1, .gridrow .col-1 {
  box-sizing: border-box;
  width: 8.3333333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-1, .gridrow .offset-1 {
  margin-left: 8.3333333333%;
}
.gridrowfull .col-2, .gridrow .col-2 {
  box-sizing: border-box;
  width: 16.6666666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-2, .gridrow .offset-2 {
  margin-left: 16.6666666667%;
}
.gridrowfull .col-3, .gridrow .col-3 {
  box-sizing: border-box;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-3, .gridrow .offset-3 {
  margin-left: 25%;
}
.gridrowfull .col-4, .gridrow .col-4 {
  box-sizing: border-box;
  width: 33.3333333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-4, .gridrow .offset-4 {
  margin-left: 33.3333333333%;
}
.gridrowfull .col-5, .gridrow .col-5 {
  box-sizing: border-box;
  width: 41.6666666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-5, .gridrow .offset-5 {
  margin-left: 41.6666666667%;
}
.gridrowfull .col-6, .gridrow .col-6 {
  box-sizing: border-box;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-6, .gridrow .offset-6 {
  margin-left: 50%;
}
.gridrowfull .col-7, .gridrow .col-7 {
  box-sizing: border-box;
  width: 58.3333333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-7, .gridrow .offset-7 {
  margin-left: 58.3333333333%;
}
.gridrowfull .col-8, .gridrow .col-8 {
  box-sizing: border-box;
  width: 66.6666666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-8, .gridrow .offset-8 {
  margin-left: 66.6666666667%;
}
.gridrowfull .col-9, .gridrow .col-9 {
  box-sizing: border-box;
  width: 75%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-9, .gridrow .offset-9 {
  margin-left: 75%;
}
.gridrowfull .col-10, .gridrow .col-10 {
  box-sizing: border-box;
  width: 83.3333333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-10, .gridrow .offset-10 {
  margin-left: 83.3333333333%;
}
.gridrowfull .col-11, .gridrow .col-11 {
  box-sizing: border-box;
  width: 91.6666666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-11, .gridrow .offset-11 {
  margin-left: 91.6666666667%;
}
.gridrowfull .col-12, .gridrow .col-12 {
  box-sizing: border-box;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.gridrowfull .offset-12, .gridrow .offset-12 {
  margin-left: 100%;
}
@media (min-width: 750px) {
  .gridrowfull .col-t, .gridrow .col-t {
    flex: 1;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t, .gridrow .offset-t {
    margin-left: 0;
  }
  .gridrowfull .col-t-1, .gridrow .col-t-1 {
    box-sizing: border-box;
    width: 8.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-1, .gridrow .offset-t-1 {
    margin-left: 8.3333333333%;
  }
  .gridrowfull .col-t-2, .gridrow .col-t-2 {
    box-sizing: border-box;
    width: 16.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-2, .gridrow .offset-t-2 {
    margin-left: 16.6666666667%;
  }
  .gridrowfull .col-t-3, .gridrow .col-t-3 {
    box-sizing: border-box;
    width: 25%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-3, .gridrow .offset-t-3 {
    margin-left: 25%;
  }
  .gridrowfull .col-t-4, .gridrow .col-t-4 {
    box-sizing: border-box;
    width: 33.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-4, .gridrow .offset-t-4 {
    margin-left: 33.3333333333%;
  }
  .gridrowfull .col-t-5, .gridrow .col-t-5 {
    box-sizing: border-box;
    width: 41.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-5, .gridrow .offset-t-5 {
    margin-left: 41.6666666667%;
  }
  .gridrowfull .col-t-6, .gridrow .col-t-6 {
    box-sizing: border-box;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-6, .gridrow .offset-t-6 {
    margin-left: 50%;
  }
  .gridrowfull .col-t-7, .gridrow .col-t-7 {
    box-sizing: border-box;
    width: 58.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-7, .gridrow .offset-t-7 {
    margin-left: 58.3333333333%;
  }
  .gridrowfull .col-t-8, .gridrow .col-t-8 {
    box-sizing: border-box;
    width: 66.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-8, .gridrow .offset-t-8 {
    margin-left: 66.6666666667%;
  }
  .gridrowfull .col-t-9, .gridrow .col-t-9 {
    box-sizing: border-box;
    width: 75%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-9, .gridrow .offset-t-9 {
    margin-left: 75%;
  }
  .gridrowfull .col-t-10, .gridrow .col-t-10 {
    box-sizing: border-box;
    width: 83.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-10, .gridrow .offset-t-10 {
    margin-left: 83.3333333333%;
  }
  .gridrowfull .col-t-11, .gridrow .col-t-11 {
    box-sizing: border-box;
    width: 91.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-11, .gridrow .offset-t-11 {
    margin-left: 91.6666666667%;
  }
  .gridrowfull .col-t-12, .gridrow .col-t-12 {
    box-sizing: border-box;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-t-12, .gridrow .offset-t-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .gridrowfull .col-d, .gridrow .col-d {
    flex: 1;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d, .gridrow .offset-d {
    margin-left: 0;
  }
  .gridrowfull .col-d-1, .gridrow .col-d-1 {
    box-sizing: border-box;
    width: 8.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-1, .gridrow .offset-d-1 {
    margin-left: 8.3333333333%;
  }
  .gridrowfull .col-d-2, .gridrow .col-d-2 {
    box-sizing: border-box;
    width: 16.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-2, .gridrow .offset-d-2 {
    margin-left: 16.6666666667%;
  }
  .gridrowfull .col-d-3, .gridrow .col-d-3 {
    box-sizing: border-box;
    width: 25%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-3, .gridrow .offset-d-3 {
    margin-left: 25%;
  }
  .gridrowfull .col-d-4, .gridrow .col-d-4 {
    box-sizing: border-box;
    width: 33.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-4, .gridrow .offset-d-4 {
    margin-left: 33.3333333333%;
  }
  .gridrowfull .col-d-5, .gridrow .col-d-5 {
    box-sizing: border-box;
    width: 41.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-5, .gridrow .offset-d-5 {
    margin-left: 41.6666666667%;
  }
  .gridrowfull .col-d-6, .gridrow .col-d-6 {
    box-sizing: border-box;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-6, .gridrow .offset-d-6 {
    margin-left: 50%;
  }
  .gridrowfull .col-d-7, .gridrow .col-d-7 {
    box-sizing: border-box;
    width: 58.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-7, .gridrow .offset-d-7 {
    margin-left: 58.3333333333%;
  }
  .gridrowfull .col-d-8, .gridrow .col-d-8 {
    box-sizing: border-box;
    width: 66.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-8, .gridrow .offset-d-8 {
    margin-left: 66.6666666667%;
  }
  .gridrowfull .col-d-9, .gridrow .col-d-9 {
    box-sizing: border-box;
    width: 75%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-9, .gridrow .offset-d-9 {
    margin-left: 75%;
  }
  .gridrowfull .col-d-10, .gridrow .col-d-10 {
    box-sizing: border-box;
    width: 83.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-10, .gridrow .offset-d-10 {
    margin-left: 83.3333333333%;
  }
  .gridrowfull .col-d-11, .gridrow .col-d-11 {
    box-sizing: border-box;
    width: 91.6666666667%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-11, .gridrow .offset-d-11 {
    margin-left: 91.6666666667%;
  }
  .gridrowfull .col-d-12, .gridrow .col-d-12 {
    box-sizing: border-box;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .gridrowfull .offset-d-12, .gridrow .offset-d-12 {
    margin-left: 100%;
  }
}
.gridrowfull.nogutter .col, .gridrowfull.nogutter .col-t, .gridrowfull.nogutter .col-d, .gridrow.nogutter .col, .gridrow.nogutter .col-t, .gridrow.nogutter .col-d {
  padding: 0;
}
.gridrowfull.nogutter .col-1, .gridrowfull.nogutter .col-t-1, .gridrowfull.nogutter .col-d-1, .gridrow.nogutter .col-1, .gridrow.nogutter .col-t-1, .gridrow.nogutter .col-d-1 {
  padding: 0;
}
.gridrowfull.nogutter .col-2, .gridrowfull.nogutter .col-t-2, .gridrowfull.nogutter .col-d-2, .gridrow.nogutter .col-2, .gridrow.nogutter .col-t-2, .gridrow.nogutter .col-d-2 {
  padding: 0;
}
.gridrowfull.nogutter .col-3, .gridrowfull.nogutter .col-t-3, .gridrowfull.nogutter .col-d-3, .gridrow.nogutter .col-3, .gridrow.nogutter .col-t-3, .gridrow.nogutter .col-d-3 {
  padding: 0;
}
.gridrowfull.nogutter .col-4, .gridrowfull.nogutter .col-t-4, .gridrowfull.nogutter .col-d-4, .gridrow.nogutter .col-4, .gridrow.nogutter .col-t-4, .gridrow.nogutter .col-d-4 {
  padding: 0;
}
.gridrowfull.nogutter .col-5, .gridrowfull.nogutter .col-t-5, .gridrowfull.nogutter .col-d-5, .gridrow.nogutter .col-5, .gridrow.nogutter .col-t-5, .gridrow.nogutter .col-d-5 {
  padding: 0;
}
.gridrowfull.nogutter .col-6, .gridrowfull.nogutter .col-t-6, .gridrowfull.nogutter .col-d-6, .gridrow.nogutter .col-6, .gridrow.nogutter .col-t-6, .gridrow.nogutter .col-d-6 {
  padding: 0;
}
.gridrowfull.nogutter .col-7, .gridrowfull.nogutter .col-t-7, .gridrowfull.nogutter .col-d-7, .gridrow.nogutter .col-7, .gridrow.nogutter .col-t-7, .gridrow.nogutter .col-d-7 {
  padding: 0;
}
.gridrowfull.nogutter .col-8, .gridrowfull.nogutter .col-t-8, .gridrowfull.nogutter .col-d-8, .gridrow.nogutter .col-8, .gridrow.nogutter .col-t-8, .gridrow.nogutter .col-d-8 {
  padding: 0;
}
.gridrowfull.nogutter .col-9, .gridrowfull.nogutter .col-t-9, .gridrowfull.nogutter .col-d-9, .gridrow.nogutter .col-9, .gridrow.nogutter .col-t-9, .gridrow.nogutter .col-d-9 {
  padding: 0;
}
.gridrowfull.nogutter .col-10, .gridrowfull.nogutter .col-t-10, .gridrowfull.nogutter .col-d-10, .gridrow.nogutter .col-10, .gridrow.nogutter .col-t-10, .gridrow.nogutter .col-d-10 {
  padding: 0;
}
.gridrowfull.nogutter .col-11, .gridrowfull.nogutter .col-t-11, .gridrowfull.nogutter .col-d-11, .gridrow.nogutter .col-11, .gridrow.nogutter .col-t-11, .gridrow.nogutter .col-d-11 {
  padding: 0;
}
.gridrowfull.nogutter .col-12, .gridrowfull.nogutter .col-t-12, .gridrowfull.nogutter .col-d-12, .gridrow.nogutter .col-12, .gridrow.nogutter .col-t-12, .gridrow.nogutter .col-d-12 {
  padding: 0;
}

html {
  font-family: "Lato", sans-serif;
  color: #131518;
  font-weight: 400;
  line-height: 1.4;
  font-size: 14px;
}
@media (min-width: 750px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

/* ======================== */
/* ======= HEADINGS ======= */
/* ======================== */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  margin: 0;
  line-height: 1.414;
}

h1, .h1, h2, .h2 {
  font-size: 2.2rem;
}

p, li, div, span, a {
  font-family: "Lato", sans-serif;
  text-decoration: none;
}

* {
  font-family: "Lato", sans-serif;
  color: #131518;
}

/* ============================== */
/* ======= TEXT ALIGNMENT ======= */
/* ============================== */
.textleft {
  text-align: left;
}

.textcenter {
  text-align: center;
}

.textright {
  text-align: right;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: #f8f9fa;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.04) 0px 2px 4px -1px;
  padding: 20px 40px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  transition: top 0.6s ease-in-out;
}
.menu.scrolled {
  top: -50vh;
}
.menu .logo {
  position: relative;
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu .logo a {
  font-size: 1em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #1F316F;
  transition-duration: 0.3s;
}
.menu .logo a:hover {
  color: #111b3f;
}
.menu .logo img {
  max-height: 40px;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
.menu .menu-items {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.menu .menu-items.open {
  display: flex;
}
.menu .menu-items > ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.menu .menu-items > ul li {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  transition-duration: 0.3s;
  padding: 10px 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}
.menu .menu-items > ul li a {
  font-weight: 700;
  transition-duration: 0.3s;
  color: #1F316F;
}
.menu .menu-items > ul li:hover a {
  font-weight: 700;
  color: #111b3f;
}
.menu .menu-items > ul li > ul {
  position: absolute;
  top: 70px;
  background-color: #495057;
  padding: 20px;
  border-radius: 25px;
}
.menu .menu-items > ul li > ul li {
  color: #f8f9fa;
  font-size: 0.8em;
}
.menu .hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.menu .hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #131518;
  margin: 4px 0;
  transition: transform 0.4s ease, opacity 0.4s ease;
  transform-origin: 5px;
}
.menu .hamburger .bar:nth-child(1).open {
  transform: rotate(45deg) translate(3px, 3px);
}
.menu .hamburger .bar:nth-child(2).open {
  opacity: 0;
}
.menu .hamburger .bar:nth-child(3).open {
  transform: rotate(-45deg) translate(5px, -5px);
}
@media (max-width: 768px) {
  .menu {
    padding: 20px;
  }
  .menu .logo img {
    max-height: 30px;
  }
  .menu .menu-items {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    background-color: #f8f9fa;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-70px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    display: flex;
    justify-content: center;
  }
  .menu .menu-items.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(70px);
  }
  .menu .menu-items > ul {
    flex-direction: column;
  }
  .menu .menu-items > ul > li {
    width: 100%;
    margin: 10px 0;
  }
  .menu .menu-items > ul > li a {
    font-weight: 700;
    font-size: 1.2em;
  }
  .menu .menu-items > ul > li:hover {
    background-color: #adb5bd;
    color: #131518;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .menu .menu-items > ul > li > ul {
    top: 0;
    position: relative;
    background-color: #adb5bd;
    width: 100%;
    padding: 20px 0;
  }
  .menu .menu-items > ul > li > ul > li {
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    color: #131518;
    font-size: 1em;
  }
  .menu .hamburger {
    display: flex;
  }
}

.toTop {
  position: fixed;
  bottom: 50px;
  right: -70px;
  width: 60px;
  height: 60px;
  background-color: #f8f9fa;
  cursor: pointer;
  z-index: 1000;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.toTop.visible {
  right: 50px;
  opacity: 1;
}
.toTop.hidden {
  right: -70px;
  opacity: 0;
}

.toTop__arrow {
  width: 100%;
  height: 100%;
  transform: rotate(180deg); /* Rotate the arrow image to point upwards */
  filter: invert(100%);
}

@media only screen and (max-width: 600px) {
  body .toTop {
    bottom: 21px;
  }
  body .toTop.visible {
    right: 21px;
  }
}
.pagetitle-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 50px;
  min-height: 50vh;
}
.pagetitle-section::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #111b3f;
  background-image: linear-gradient(135deg, #1F316F 0%, #111b3f 100%);
  opacity: 1;
  z-index: 1;
  mix-blend-mode: soft-light;
}
.pagetitle-section::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #131518;
  opacity: 0.3;
  z-index: 1;
}
.pagetitle-section img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.pagetitle-section h1 {
  font-size: 3em;
  font-weight: 700;
  color: #131518;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  z-index: 2;
  color: #f8f9fa;
}
.pagetitle-section p {
  margin-top: 10px;
  color: #f8f9fa;
  z-index: 2;
  font-size: 1.4em;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  body .pagetitle-section {
    padding-bottom: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 60px;
  }
  body .pagetitle-section p {
    text-align: center;
    line-height: 1.2em;
    margin-top: 20px;
  }
}
.footer-section {
  padding: 50px 0;
  border-top: 1px solid #131518;
  box-sizing: border-box;
}
.footer-section .footer-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.footer-section .footer-content .footer-left {
  display: flex;
  align-items: center;
}
.footer-section .footer-content .footer-left span {
  font-size: 1.2em;
  color: #495057;
}
.footer-section .footer-content .footer-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.footer-section .footer-content .footer-center ul li {
  width: 100%;
  margin: 10px 0;
}
.footer-section .footer-content .footer-center ul li a {
  font-size: 1.2em;
  color: #131518;
}
.footer-section .footer-content .footer-center ul li:hover a {
  color: #adb5bd;
}
.footer-section .footer-content .footer-right {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.footer-section .footer-content .footer-right ul li {
  width: 100%;
  margin: 10px 0;
}
.footer-section .footer-content .footer-right ul li a {
  font-size: 1.2em;
  color: #131518;
}
.footer-section .footer-content .footer-right ul li:hover a {
  color: #adb5bd;
}

@media only screen and (max-width: 600px) {
  body .footer-section .footer-content {
    align-content: flex-start;
    align-items: flex-start;
  }
  body .footer-section .footer-content .footer-left {
    justify-content: center;
  }
  body .footer-section .footer-content .footer-center {
    margin-top: 20px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 40px;
  }
  body .footer-section .footer-content .footer-center li {
    text-align: center;
  }
  body .footer-section .footer-content .footer-right {
    margin-top: 20px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 30px;
  }
  body .footer-section .footer-content .footer-right ul li {
    text-align: center;
  }
}
.cover-section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  height: 80vh;
  position: relative;
}
.cover-section::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #131518;
  opacity: 0.6;
  z-index: 1;
}
.cover-section .cover-container {
  position: relative;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  z-index: 2;
}
.cover-section .cover-container h1 {
  display: block;
  font-size: 4em;
  font-weight: 700;
  text-transform: uppercase;
  color: #f8f9fa;
}
.cover-section .cover-container span {
  margin-top: 20px;
  display: block;
  font-size: 1.5em;
  font-weight: 500;
  color: #f8f9fa;
  line-height: 1.2em;
}
.cover-section .cover-container a {
  margin-top: 40px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}
.cover-section .cover-container a .button {
  margin-left: 15px;
  border-color: #f8f9fa;
  color: white;
}
.cover-section .cover-container a .button:hover {
  background-color: #f8f9fa;
  color: #131518;
}
.cover-section .cover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cover-section .cover-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (max-width: 600px) {
  body .cover-section {
    height: 60vh;
    margin-top: 60px;
  }
  body .cover-section .cover-container h1 {
    font-size: 2em;
  }
  body .cover-section .cover-container span {
    font-size: 1.2em;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  body .cover-section {
    height: 90vh;
  }
  body .cover-section .cover-container h1 {
    font-size: 3em;
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  border: 1px solid #131518;
  background-color: rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}
.button:hover {
  background-color: #131518;
  color: #f8f9fa;
}

.produtos-destaque-section {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 50px 0;
}
.produtos-destaque-section .produtos-destaque-titulo h2 {
  color: #1F316F;
}
.produtos-destaque-section .produtos-destaque-lista {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item {
  width: calc(25% - 15px);
  margin: 0 10px;
  padding-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  height: auto;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item:hover img {
  transform: scale(1.1);
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item:first-child {
  margin-left: 0;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item:last-child {
  margin-right: 0;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-imagem {
  height: 250px;
  overflow: hidden;
  position: relative;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-imagem img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  transition-duration: 0.3s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-nome {
  margin: 10px 0;
  padding: 10px;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-nome h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #131518;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-caracteristicas {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 5px;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-caracteristicas span {
  font-weight: 300;
  margin: 5px 4px;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}
.produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-caracteristicas .preco {
  display: block;
  color: #1F316F;
  font-weight: 700;
  font-size: 1.4em;
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  body .produtos-destaque-section .produtos-destaque-lista {
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 10px 15px;
    margin-top: 20px;
  }
  body .produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item {
    width: 200px;
    overflow: visible;
  }
  body .produtos-destaque-section .produtos-destaque-lista .produtos-destaque-item .produtos-destaque-item-imagem {
    height: 200px;
  }
}
.lista-produtos-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
.lista-produtos-section .lista-produtos-container {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.lista-produtos-section .lista-produtos-container .aviso-texto {
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  width: 100%;
  color: #131518;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item {
  width: calc(25% - 20px);
  margin: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item:hover img {
  transform: scale(1.1);
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-imagem {
  height: 250px;
  overflow: hidden;
  position: relative;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-imagem img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  transition-duration: 0.3s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-nome {
  margin: 10px 0;
  padding: 10px;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-nome h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-caracteristicas {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-caracteristicas span {
  font-weight: 300;
  margin: 5px 10px;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}
.lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-conteudo .produtos-destaque-item-caracteristicas .preco {
  display: block;
  color: #1F316F;
  font-weight: 700;
  font-size: 1.4em;
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  body .lista-produtos-section .lista-produtos-container {
    padding: 10px 15px;
    margin-top: 20px;
  }
  body .lista-produtos-section .lista-produtos-container .produtos-destaque-item {
    width: 100%;
    overflow: visible;
  }
  body .lista-produtos-section .lista-produtos-container .produtos-destaque-item .produtos-destaque-item-imagem {
    height: 200px;
  }
}
.galeria-produto-container {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.galeria-produto-container .galeria-produto-imagens {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.galeria-produto-container .galeria-produto-imagens .imagem-principal {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 450px;
  position: relative;
}
.galeria-produto-container .galeria-produto-imagens .imagem-principal .slide-image {
  flex: 0 0 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  width: 100%;
}
.galeria-produto-container .galeria-produto-imagens .navegacao-imagens {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.galeria-produto-container .galeria-produto-imagens .navegacao-imagens button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  color: #131518;
  width: 50px;
  height: 50px;
  font-size: 1.5em;
  transition-duration: 0.3s;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.3;
}
.galeria-produto-container .galeria-produto-imagens .navegacao-imagens button:hover {
  opacity: 1;
}
.galeria-produto-container .miniaturas-imagens {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 0;
  width: 100%; /* Ensure it takes full width */
  scroll-behavior: smooth; /* Smooth scrolling */
  /* Optional: Add scrollbar styling if desired */
}
.galeria-produto-container .miniaturas-imagens ::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}
.galeria-produto-container .miniaturas-imagens ::-webkit-scrollbar-thumb {
  background-color: #adb5bd; /* Adjust scrollbar color */
  border-radius: 10px;
}
.galeria-produto-container .miniaturas-imagens ::-webkit-scrollbar-thumb:hover {
  background-color: #adb5bd; /* Darker color on hover */
}
.galeria-produto-container .miniaturas-imagens img {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.5;
  margin: 10px 5px;
  border-radius: 5px;
}
.galeria-produto-container .miniaturas-imagens img:first-child {
  margin-left: 0;
}
.galeria-produto-container .miniaturas-imagens img.ativa {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  body .galeria-produto-container .galeria-produto-imagens .imagem-principal {
    height: 350px;
  }
  body .galeria-produto-container .galeria-produto-imagens .imagem-principal .slide-image {
    width: 100%;
  }
}
.info-anuncio-container {
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
}
.info-anuncio-container .info-anuncio-nome {
  padding-bottom: 20px;
}
.info-anuncio-container .info-anuncio-nome h1 {
  line-height: 1.2em;
  color: #1F316F;
}
.info-anuncio-container .info-anuncio-breve {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  border-top: 1px solid #131518;
}
.info-anuncio-container .info-anuncio-breve span {
  width: 100%;
  margin: 5px 0;
}
.info-anuncio-container .info-anuncio-preco {
  margin-top: 20px;
}
.info-anuncio-container .info-anuncio-preco span {
  font-size: 3em;
  font-weight: 500;
  color: #1F316F;
}
.info-anuncio-container .ver-mais-caracteristicas {
  margin-top: 30px;
}
.info-anuncio-container .ver-mais-caracteristicas a {
  color: #1F316F;
  text-transform: uppercase;
  transition-duration: 0.3s;
}
.info-anuncio-container .ver-mais-caracteristicas a:hover {
  color: #111b3f;
}
.info-anuncio-container .contactar-button {
  margin-top: 30px;
}
.info-anuncio-container .contactar-button .contactar-email {
  margin-top: 10px;
  display: flex;
}
.info-anuncio-container .contactar-button button {
  background-color: #1F316F;
  color: #f8f9fa;
  border-color: #1F316F;
}
.info-anuncio-container .contactar-button button:hover {
  background-color: #111b3f;
}

.breadcrumbs {
  padding: 30px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 100px;
}
.breadcrumbs a {
  text-transform: uppercase;
  margin: 0 5px;
  font-weight: 400;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0.5;
}
.breadcrumbs a:first-child {
  margin-left: 0;
}
.breadcrumbs a::after {
  content: "/";
  margin-left: 10px;
}
.breadcrumbs a:hover {
  opacity: 1;
}
.breadcrumbs span {
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  body .breadcrumbs {
    padding: 20px 15px;
  }
}
.caracteristicas-produtos {
  margin-top: 30px;
  padding: 15px;
  box-sizing: border-box;
}
.caracteristicas-produtos .caracteristicas-produtos-container {
  background-color: #e9ecef;
  padding: 40px;
  border-radius: 8px;
  box-sizing: border-box;
}
.caracteristicas-produtos .caracteristicas-produtos-container h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #1F316F;
}
.caracteristicas-produtos .caracteristicas-produtos-container .lista-caracteristicas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 48%;
}
.caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item .label {
  font-weight: bold;
  margin-right: 5px;
  color: #131518;
}
.caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item .valor {
  color: #131518;
}

@media only screen and (max-width: 600px) {
  body .caracteristicas-produtos .caracteristicas-produtos-container {
    padding: 20px;
  }
  body .caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item {
    width: 100%;
    margin: 10px 0;
  }
}
.descricao-produto-container {
  padding: 15px;
  box-sizing: border-box;
}
.descricao-produto-container .descricao-produto-conteudo {
  padding: 40px;
  background-color: #e9ecef;
  border-radius: 10px;
}
.descricao-produto-container .descricao-produto-conteudo h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #1F316F;
}
.descricao-produto-container .descricao-produto-conteudo p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #131518;
}

@media only screen and (max-width: 600px) {
  body .descricao-produto-container .descricao-produto-conteudo {
    padding: 20px;
  }
  body .caracteristicas-produtos .caracteristicas-produtos-container .caracteristica-item {
    width: 100%;
    margin: 10px 0;
  }
}
.sobre-nos-container {
  padding: 15px;
  box-sizing: border-box;
}
.sobre-nos-container .sobre-nos-conteudo {
  padding: 100px 40px;
  border-radius: 10px;
}
.sobre-nos-container .sobre-nos-conteudo h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #1F316F;
}
.sobre-nos-container .sobre-nos-conteudo p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #131518;
}

@media only screen and (max-width: 600px) {
  body .sobre-nos-container .sobre-nos-conteudo {
    padding: 20px;
  }
}
.contacto-produto-section {
  padding: 100px 0;
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
}
.contacto-produto-section.produtos-contacto-section {
  margin-top: 50px;
}
.contacto-produto-section .contacto-produto-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 100px 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  text-align: center;
}
.contacto-produto-section .contacto-produto-container h2 {
  font-size: 2rem;
  color: #1F316F;
  margin-bottom: 20px;
}
.contacto-produto-section .contacto-produto-container p {
  font-size: 1.4rem;
  line-height: 1.2em;
  color: #495057;
  margin-bottom: 30px;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form .form-group {
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
  margin-top: 20px;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form .form-group label {
  display: block;
  font-size: 1.4rem;
  color: #131518;
  margin-bottom: 5px;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form .form-group input, .contacto-produto-section .contacto-produto-container .contacto-produto-form .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 10px;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form .form-group textarea {
  resize: vertical;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form .contacto-produto-submit {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  color: #f8f9fa;
  background-color: #1F316F;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.contacto-produto-section .contacto-produto-container .contacto-produto-form .contacto-produto-submit:hover {
  background-color: #111b3f;
}

@media only screen and (max-width: 600px) {
  body .contacto-produto-section {
    padding: 50px 0;
  }
  body .contacto-produto-section .contacto-produto-container {
    padding: 50px 20px;
  }
}
.produtos-section {
  box-sizing: border-box;
  padding: 0 50px;
  margin-top: 50px;
}

.filtro-produtos-container {
  position: relative;
  background-color: #e9ecef;
  padding: 40px 0;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  height: -moz-fit-content;
  height: fit-content;
}
.filtro-produtos-container form h3 {
  font-size: 1.4em;
}
.filtro-produtos-container form .filtro-principal, .filtro-produtos-container form .filtro-avancado {
  margin-top: 20px;
}
.filtro-produtos-container form .filtro-principal label, .filtro-produtos-container form .filtro-avancado label {
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.filtro-produtos-container form .filtro-principal label select, .filtro-produtos-container form .filtro-principal label input, .filtro-produtos-container form .filtro-avancado label select, .filtro-produtos-container form .filtro-avancado label input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #f8f9fa;
  border-color: rgba(0, 0, 0, 0);
  opacity: 1;
  border-radius: 5px;
}
.filtro-produtos-container form button {
  width: 100%;
  border-radius: 10px;
  background-color: #f8f9fa;
  font-size: 1em;
  border: 1px solid #131518;
  padding: 10px 0;
  margin: 10px 0;
  cursor: pointer;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filtro-produtos-container form button:hover {
  background-color: #131518;
  color: #f8f9fa;
}
.filtro-produtos-container form .filtro-avancado-button {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  body .produtos-section {
    padding: 0;
  }
}
.intro-empresa-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.intro-empresa-section .intro-empresa-texto {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
}
.intro-empresa-section .intro-empresa-texto h2, .intro-empresa-section .intro-empresa-texto h3 {
  color: #1F316F;
  font-size: 2.2em;
}
.intro-empresa-section .intro-empresa-texto p {
  margin-top: 20px;
  padding: 0;
  font-size: 1.4em;
  line-height: 1.2em;
}
.intro-empresa-section .intro-empresa-texto a {
  margin-top: 40px;
}
.intro-empresa-section .intro-empresa-texto a button {
  background-color: #1F316F;
  color: #f8f9fa;
}
.intro-empresa-section .intro-empresa-texto a button:hover {
  background-color: #111b3f;
}
.intro-empresa-section .intro-empresa-items {
  display: flex;
  justify-content: center;
  align-items: normal;
  flex-wrap: wrap;
  margin-top: 100px;
  padding: 0;
}
.intro-empresa-section .intro-empresa-items .intro-empresa-texto {
  padding: 50px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 20px;
  margin: 15px;
  width: calc(50% - 30px);
  height: auto;
}

@media only screen and (max-width: 600px) {
  body .intro-empresa-section .intro-empresa-items .intro-empresa-texto {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
}
.numeros-empresa-section {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #1F316F;
  background-image: linear-gradient(135deg, #1F316F 0%, #111b3f 100%);
  margin-top: 100px;
  padding: 100px 0;
}
.numeros-empresa-section .numeros-empresa-titulo {
  font-size: 2.2em;
  color: #f8f9fa;
  text-align: center;
  text-transform: uppercase;
}
.numeros-empresa-section .numeros-empresa-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.numeros-empresa-section .numeros-empresa-container .numeros-empresa-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.numeros-empresa-section .numeros-empresa-container .numeros-empresa-item .numero {
  font-size: 4em;
  color: #f8f9fa;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
.numeros-empresa-section .numeros-empresa-container .numeros-empresa-item .info-numero {
  font-size: 1.4em;
  color: #f8f9fa;
  font-weight: 300;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  body .numeros-empresa-section .numeros-empresa-container .numeros-empresa-item {
    margin: 40px 0;
  }
}
.info-contactos-section {
  position: relative;
  flex-wrap: wrap;
  margin-top: 100px;
}
.info-contactos-section .info-contactos-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.info-contactos-section .info-contactos-container .info-contactos-texto {
  margin-top: 100px;
}
.info-contactos-section .info-contactos-container .info-contactos-texto:first-child {
  margin-top: 0;
}
.info-contactos-section .info-contactos-container .info-contactos-texto h2, .info-contactos-section .info-contactos-container .info-contactos-texto h3 {
  color: #1F316F;
  font-size: 2.2em;
  line-height: 1.2em;
}
.info-contactos-section .info-contactos-container .info-contactos-texto p {
  margin-top: 20px;
  padding: 0;
  font-size: 1.4em;
  line-height: 1.2em;
}

@media only screen and (max-width: 600px) {
  body .info-contactos-section .info-contactos-localizacao .info-contactos-localizacao-mapa {
    margin-top: 50px;
  }
}
.ligue-section {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #1F316F;
  background-image: linear-gradient(135deg, #1F316F 0%, #111b3f 100%);
  margin-top: 100px;
  padding: 100px 0;
}
.ligue-section .ligue-container {
  display: flex;
  justify-content: center;
}
.ligue-section .ligue-container .ligue-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.ligue-section .ligue-container .ligue-content h2 {
  font-size: 2.2em;
  color: #f8f9fa;
  text-align: center;
  text-transform: uppercase;
}
.ligue-section .ligue-container .ligue-content p {
  font-size: 1.4em;
  color: #f8f9fa;
  line-height: 1.2em;
  margin-top: 20px;
  font-weight: 300;
}
.ligue-section .ligue-container .ligue-content a {
  position: relative;
  margin-top: 40px;
}
.ligue-section .ligue-container .ligue-content a button {
  background-color: rgba(0, 0, 0, 0);
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.ligue-section .ligue-container .ligue-content a button:hover {
  background-color: #f8f9fa;
  color: #1F316F;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  z-index: 9999;
}

.spinner {
  border: 8px solid #f8f9fa;
  border-top: 8px solid #1F316F;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
html, body {
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1200px) {
  * {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
  *::-webkit-scrollbar {
    display: none !important;
  }
}/*# sourceMappingURL=style.css.map */